<template>
  <a-spin :spinning="spinning" tip="登录中..." size="large">
    <a-layout class="ui-main">
      <div class="ui-content">
		  <div class="ui-content__left" @click="onPrintTicket">
			  <img src="../../assets/image/loginImg.png"/>
		  </div>
        <div class="ui-content__right">
          <div class="ui-project">
            <img class="ui-project__logo" :src="$store.state.system.projectSetting.logo" />
            <div class="ui-project__title">{{ $store.state.system.projectSetting.name }}</div>
          </div>
          <!-- <div class="ui-desc">后台管理系统</div> -->

          <div class="ui-form">
            <a-form
              :model="formState"
              name="basic"
              autocomplete="off"
              @finish="onFinish"
              @finishFailed="onFinishFailed"
            >
              <a-form-item
                class="ui-form__item"
                name="username"
                :rules="[
                  { required: true, message: 'Please input your username!' },
                ]"
              >
                <a-input
					class="ui-form__input"
                  size="large"
                  v-model:value="formState.username"
                  placeholder="账号"
                >
                  <template #prefix>
                    <Icon icon="UserOutlined" class="ui-input__icon"></Icon>
                  </template>
                </a-input>
              </a-form-item>

              <a-form-item
                class="ui-form__item"
                name="password"
                :rules="[
                  { required: true, message: 'Please input your password!' },
                ]"
              >
                <a-input-password
					class="ui-form__input"
                  v-model:value="formState.password"
                  size="large"
                  placeholder="密码"
                >
                  <template #prefix>
                    <Icon icon="KeyOutlined" class="ui-input__icon"></Icon>
                  </template>
                </a-input-password>
              </a-form-item>

              <a-form-item class="ui-form__item">
                <a-button
                  class="ui-submit"
                  block
                  type="primary"
                  html-type="submit"
                  >登录</a-button
                >
              </a-form-item>
            </a-form>
          </div>
        </div>

		<a-modal v-model:visible="visible" :footer="null">
			<a-alert style="margin-top: 20px;" message="系统到期,请续费后联系工作人员开通!" type="warning"></a-alert>
			<div class="ui-renewal">
				<img :src="$store.state.system.reneSetting.qrCode"/>
			</div>
		</a-modal>
      </div>
      <a-layout-footer class="ui-footer"> Copyright 2022</a-layout-footer>
    </a-layout>
  </a-spin>
</template>

<script>
import { login, getPower, getRenewDetail, getCinemaSetting } from '@/service/modules/system';
import { initRouter } from "@/utils/routerUtil";
import store from '@/store';
import { Icon } from "@/components/icon/icon.js";
import printJS from 'print-js'
export default {
  components: { Icon },
  data() {
    return {
      spinning: false,
      formState: {
        username: "",
        password: "",
      },
	  visible: false
    };
  },
  created() {
    this.getRenewDetail();
  },
  methods: {
    onFinish(values) {
      this.spinning = true;
      login(values).then(res => {
		  // this.visible = true;
		  // this.spinning = false;
		  // return;
        if (res.code == 200) {
		  getCinemaSetting({}).then((result) =>{
			this.$store.commit("cinema/setUiVersion", result.data.uiVersion);
		  })
          getPower({}).then(menuData => {
            this.spinning = false;
            if (menuData.code == 200) {
              let permissionList = menuData.data.powerApis.split(',');
              values.name = values.username;
			  values.id = res.data;
              this.$store.commit("system/setLogin", true);
              this.$store.commit("system/setUserIfo", values);
              this.$store.commit("system/set_menuList", menuData.data.adminMenus);
              // 设置权限Code列表
              this.$store.commit("system/setPermission", permissionList);
              // 加载有权限的路由
              initRouter();
              this.$router.replace("/");
            }
          });
        } else if (res.code === 1008) {
			this.spinning = false;
			this.visible = true;
		} else {
          this.spinning = false;
        }
      }).catch(err=> {
		  console.log(err)
        this.spinning = false;
      });
    },
    onFinishFailed(errorInfo) {
      //console.log("Failed:", errorInfo);
    },
    async getRenewDetail() {
        getRenewDetail({}).then((ret) => {
            if (ret.code === 200) {
                store.commit('system/reneSetting', ret.data);
            }
        })
    },
    onPrintTicket() {
      printJS({
        type: 'pdf',
        printable: 'https://lingren-test-shenzhen.oss-cn-shenzhen.aliyuncs.com/lrCinema/upload/export/e7b0b554-2712-4e20-8bab-31a2dfcdbb00.pdf',
        onPrintDialogClose: () => {
          // 关闭打印弹窗回调
        },
        onLoadingStart: () => {
          // 开始加载pdf完成回调
        },
        onLoadingEnd: () => {
          // 加载pdf完成回调
        },
        onError: (err)=>{
          // TODO 打印异常处理
          console.log(err)
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.ui-main {
  width: 100vw;
  height: 100vh;
  padding: 80px 0 0;
  background: #f0f2f5 url("../../assets/image/loginBgc.jpg");
}
.ui-content {
	width: 800px;
	margin: 0 auto;
	line-height: 0;
	background-color: #FFF;
	border-radius: 15px;
}
.ui-content__left {
	display: inline-block;
	float: left;
	padding: 84px 50px 78px 30px;
}
.ui-content__right {
	display: inline-block;
	width: 408px;
	padding: 40px 30px;
	float: right;
}
.ui-project {
  display: flex;
  align-items: center;
  justify-content: center;

  .ui-project__logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .ui-project__title {
    font-size: 34px;
	line-height: 50px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
  }
}
.ui-desc {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 12px;
  margin-bottom: 40px;
}
.ui-form {
  width: 330px;
  margin: 60px auto;
  text-align: left;


  .ui-form__item {
    margin-bottom: 20px;
  }

  .ui-input__icon {
    color: rgba(0, 0, 0, 0.25);
  }

  .ui-submit {
    height: 38px;
    margin-top: 20px;
	border-radius: 10px;
  }
}
.ui-renewal {
	padding-top: 20px;
	text-align: center;

	img {
		width: 300px;
	}
}

.ui-form__input {
	border-radius: 20px;
}
.ui-footer {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  background: TRANSPARENT;
}
</style>
